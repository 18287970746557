body {
  margin: 0;
  /*  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* font-family: 'Audiowide', cursive;
font-family: 'Gugi', cursive;
font-family: 'Poppins', sans-serif; */
p,
h1,
h2,
h3,
h4,
h5,
h6,

div {
  font-family: "Poppins", sans-serif;
}

span {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
}

/* span {
  font-family: "Poppins", sans-serif;
}
 */
