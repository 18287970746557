/* .modal-content {
justify-content: center;
align-items: center;

background-color:'#00000000';

} */

.my-modal {
  width: 90vw; /* Occupy the 90% of the screen width */
  max-width: 90vw;
}
